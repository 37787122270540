import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';  // Importer Link pour la navigation
import { signup } from '../utils/api';  // Importer la fonction d'inscription API
import { useState } from 'react';

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Requis'),
  email: Yup.string().email('Email invalide').required('Requis'),
  password: Yup.string().min(6, 'Trop court').required('Requis'),
  role: Yup.string().required('Requis')
});

function SignupPage() {
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState('');

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>S'inscrire</h2>

      <Formik
        initialValues={{ name: '', email: '', password: '', role: 'student' }}
        validationSchema={SignupSchema}
        onSubmit={async (values) => {
          try {
            const response = await signup(values);  // Appel à l'API d'inscription
            if (response.status === 'success') {
              setResponseMessage('Compte créé avec succès');
              navigate('/login');  // Redirection vers la page de connexion après succès
            } else {
              setResponseMessage(response.message);  // Affichage du message d'erreur
            }
          } catch (error) {
            console.error('Erreur lors de l\'inscription', error);
            setResponseMessage('Erreur lors de l\'inscription. Veuillez réessayer.');
          }
        }}
      >
        {() => (
          <Form style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', margin: '0 auto' }}>
            <Field type="text" name="name" placeholder="Nom" style={{ padding: '10px', margin: '10px 0' }} />
            <ErrorMessage name="name" component="div" style={{ color: 'red', fontSize: '14px' }} />
            
            <Field type="email" name="email" placeholder="Email" style={{ padding: '10px', margin: '10px 0' }} />
            <ErrorMessage name="email" component="div" style={{ color: 'red', fontSize: '14px' }} />

            <Field type="password" name="password" placeholder="Mot de passe" style={{ padding: '10px', margin: '10px 0' }} />
            <ErrorMessage name="password" component="div" style={{ color: 'red', fontSize: '14px' }} />

            <Field as="select" name="role" style={{ padding: '10px', margin: '10px 0' }}>
              <option value="student">Étudiant</option>
              <option value="professor">Professeur</option>
            </Field>
            <ErrorMessage name="role" component="div" style={{ color: 'red', fontSize: '14px' }} />

            <button type="submit" style={{ padding: '10px', marginTop: '20px' }}>S'inscrire</button>

            {/* Affichage du message de retour de l'API */}
            {responseMessage && <p>{responseMessage}</p>}
          </Form>
        )}
      </Formik>

      {/* Ajout du lien vers la page de connexion */}
      <p style={{ marginTop: '20px' }}>
        Déjà inscrit ? <Link to="/login">Connectez-vous</Link>
      </p>
    </div>
  );
}

export default SignupPage;
