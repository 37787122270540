import { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null); // Contient les informations sur l'utilisateur connecté

  const login = (userData) => {
    setUser(userData); // Stocker l'utilisateur connecté avec son rôle
  };

  const logout = () => {
    setUser(null); // Déconnexion
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
