import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Bienvenue sur la plateforme de gestion des disponibilités</h1>
      <p>Cette application permet aux élèves de renseigner leurs disponibilités et aux professeurs de les consulter pour organiser des rendez-vous.</p>
      
      <div style={{ marginTop: '20px' }}>
        <Link to="/login" style={{ marginRight: '20px', textDecoration: 'none' }}>
          <button>Se connecter</button>
        </Link>

        <Link to="/signup" style={{ marginRight: '20px', textDecoration: 'none' }}>
          <button>S'inscrire</button>
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
