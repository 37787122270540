import { useState } from 'react';

function TeacherDashboard() {
  const [calendars, setCalendars] = useState([]);

  const createCalendar = (calendarName) => {
    setCalendars([...calendars, { name: calendarName, availability: [] }]);
  };

  return (
    <div>
      <h2>Tableau de bord Professeur</h2>
      <button onClick={() => createCalendar('Nouveau cours')}>Créer un calendrier</button>
      <ul>
        {calendars.map((cal, index) => (
          <li key={index}>{cal.name}</li>
        ))}
      </ul>
    </div>
  );
}

export default TeacherDashboard;
