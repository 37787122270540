import axios from 'axios';

const API_URL = 'https://api.todoom.eu/';

export const login = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}login`, credentials, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la connexion :', error);
    throw error;
  }
};


// Fonction pour inscrire un nouvel utilisateur
export const signup = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}signup`, userData);
    return response.data;  // Retourner la réponse JSON
  } catch (error) {
    console.error('Erreur lors de l\'inscription :', error);
    return error.response.data;  // Retourner l'erreur reçue du serveur
  }
};


// Fonction pour créer un calendrier
export const createCalendar = async (calendarData) => {
  try {
    const response = await axios.post(`${API_URL}create_calendar`, calendarData);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création du calendrier :', error);
    return error.response.data;
  }
};

// Fonction pour ajouter une disponibilité
export const addAvailability = async (availabilityData) => {
  try {
    const response = await axios.post(`${API_URL}add_availability`, availabilityData);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'ajout de la disponibilité :', error);
    return error.response.data;
  }
};

// Fonction pour mettre à jour le rôle d'un utilisateur (admin)
export const updateRole = async (roleData) => {
  try {
    const response = await axios.post(`${API_URL}update_role`, roleData);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du rôle :', error);
    return error.response.data;
  }
};
