import { useState } from 'react';

function AdminPanel() {
  const [users, setUsers] = useState([
    { id: 1, name: 'John Doe', role: 'student' },
    { id: 2, name: 'Jane Smith', role: 'professor' }
  ]);

  const changeUserRole = (userId, newRole) => {
    setUsers(users.map(user => user.id === userId ? { ...user, role: newRole } : user));
    // Envoi vers l'API pour sauvegarder les modifications
  };

  return (
    <div>
      <h2>Panneau d'administration</h2>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            {user.name} - {user.role}
            <select onChange={(e) => changeUserRole(user.id, e.target.value)} value={user.role}>
              <option value="student">Étudiant</option>
              <option value="professor">Professeur</option>
              <option value="admin">Admin</option>
            </select>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminPanel;
