import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage'; // Ajout de la page de connexion
import SignupPage from './pages/SignupPage'; // Ajout de la page d'inscription
import TeacherDashboard from './pages/TeacherDashboard'; // Ajout de la page de tableau de bord pour les professeurs
import AdminPanel from './pages/AdminPanel'; // Ajout de la page de tableau de bord pour les administrateurs

import { useUser } from './utils/UserContext';
import { Navigate } from 'react-router-dom';

// Composant pour protéger les routes
function ProtectedRoute({ children, roleRequired }) {
  const { user } = useUser();
  
  if (!user || (roleRequired && user.role !== roleRequired)) {
    return <Navigate to="/login" />; // Redirige si l'utilisateur n'est pas connecté ou n'a pas le bon rôle
  }

  return children;
}


function App() {
  return (
    <Router>
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/dashboard" element={<ProtectedRoute roleRequired="student"><Dashboard /></ProtectedRoute>} />
    <Route path="/teacher-dashboard" element={<ProtectedRoute roleRequired="professor"><TeacherDashboard /></ProtectedRoute>} />
    <Route path="/admin-panel" element={<ProtectedRoute roleRequired="admin"><AdminPanel /></ProtectedRoute>} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/signup" element={<SignupPage />} />
  </Routes>
</Router>

  );
}

export default App;
