import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';  // Importer Link pour la navigation
import { login } from '../utils/api';  // Importer la fonction de connexion API
import { useState } from 'react';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Requis'),
  password: Yup.string().min(6, 'Trop court').required('Requis'),
});

function LoginPage() {
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState('');

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Se connecter</h2>

      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={async (values) => {
          try {
            const response = await login(values);  // Appel à l'API via api.js
            if (response.status === 'success') {
              setResponseMessage('Connexion réussie');
              navigate('/dashboard');  // Redirection vers le tableau de bord après succès
            } else {
              setResponseMessage(response.message);  // Affichage du message d'erreur
            }
          } catch (error) {
            console.error('Erreur lors de la connexion', error);
            setResponseMessage('Erreur lors de la connexion. Veuillez réessayer.');
          }
        }}
      >
        {() => (
          <Form style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', margin: '0 auto' }}>
            <Field type="email" name="email" placeholder="Email" style={{ padding: '10px', margin: '10px 0' }} />
            <ErrorMessage name="email" component="div" style={{ color: 'red', fontSize: '14px' }} />
            
            <Field type="password" name="password" placeholder="Mot de passe" style={{ padding: '10px', margin: '10px 0' }} />
            <ErrorMessage name="password" component="div" style={{ color: 'red', fontSize: '14px' }} />

            <button type="submit" style={{ padding: '10px', marginTop: '20px' }}>Connexion</button>

            {/* Affichage du message de retour de l'API */}
            {responseMessage && <p>{responseMessage}</p>}
          </Form>
        )}
      </Formik>

      {/* Ajout du lien vers la page d'inscription */}
      <p style={{ marginTop: '20px' }}>
        Pas encore inscrit ? <Link to="/signup">Créez un compte</Link>
      </p>
    </div>
  );
}

export default LoginPage;
